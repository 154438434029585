// src/utils/nprogress.js
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })

let count = 0

const startCount = () => {
  if (++count === 1) {
    NProgress.start()
  }
}

const handleProgress = () => {
  if (--count <= 0) {
    setTimeout(() => NProgress.done(), 200)
  } else {
    NProgress.inc()
  }
}

export { startCount, handleProgress }