import store from "@/store";
import {
  apiUrl,
  requestTimeout,
  contentType,
  excludeErrorUrls,
} from "@/config";
import axios from "axios";
import { startCount, handleProgress } from "@/utils/nprogress";
import qs from "qs";
import Vue from "vue";

const service = axios.create({
  baseURL: apiUrl,
  timeout: requestTimeout,
  headers: {
    "Content-Type": contentType,
  },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    startCount();

    const token = store.getters["user/token"];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (
      config.data &&
      config.headers["Content-Type"] ===
        "application/x-www-form-urlencoded;charset=UTF-8"
    ) {
      config.data = qs.stringify(config.data);
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    handleProgress();
    const { code, msg } = response.data;

    if (
      code !== 200 &&
      !excludeErrorUrls.some((url) => response.config.url.includes(url))
    ) {
      if (code == 401) {
        // window.location.replace('#/login');
        return;
      } else {
        Vue.prototype.$message.error(msg);
      }
      return Promise.reject(response);
    }

    return response.data;
  },
  (error) => {
    handleProgress();
    console.error("Network Error:", error.message);
    return Promise.reject(error);
  }
);

export default service;
