import { tokenName } from '@/config'
import * as userService from '@/services/user'
import { getToken, removeToken, setToken } from '@/utils/token'
import { set } from 'nprogress'

const state = () => ({
    token: getToken(),
    name: '游客',
    avatar: 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif',
    userInfo: {}
})

const getters = {
    token: state => state.token,
    name: state => state.name,
    avatar: state => state.avatar,
    userInfo: state => state.userInfo
}

const mutations = {
    setToken(state, token) {
        state.token = token
        setToken(token)
    },
    setName(state, name) {
        state.name = name
    },
    setAvatar(state, avatar) {
        state.avatar = avatar
    },
    setUserInfo(state, userInfo) {
        state.userInfo = userInfo
    },
    resetAll(state) {
        state.name = ''
        state.avatar = ''
        state.token = ''
    }
}

const actions = {
    async login({ commit }, params) {
        const { data: { [tokenName]: token } } = await userService.login(params)
        if (token) commit('setToken', token)
    },
    async codeLogin({ commit }, params) {
        const { data: { [tokenName]: token } } = await userService.codeLogin(params)
        if (token) commit('setToken', token)
    },

    async userInfo({ commit }) {
        const { data } = await userService.getUserInfo()
        console.log(data);
        localStorage.setItem('name', data.name || '游客')
        localStorage.setItem('avatar', data.avatar || 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif')
        commit('setUserInfo', data)
        if (data.name) commit('setName', data.name)
        if (data.avatar) commit('setAvatar', data.avatar)
    },

    async logout({ dispatch }) {
        // await userService.logout()
        await dispatch('resetAll')
    },

    async resetAll({ commit }) {
        localStorage.clear()
        commit('resetAll')
        removeToken()
    },

    setToken({ commit }, token) {
        commit('setToken', token)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}