import http from "@/utils/http";

// 获取导航菜单
export const getNavMenu = (params) => http.post('/t-index-menu/indexList', params)

// 获取关于我们信息
export const getAboutUs = () => http.post('/t-info-config/getInfoConfig?id=1')

// 获取标语
export const getSlogan = () => http.post('/t-info-config/getInfoConfig?id=2')

// 获取购买须知
export const getPurchaseNotes = () => http.post('/t-info-config/getInfoConfig?id=3')

// 获取省市信息树
export const getRegionTree = () => http.post('/web/region/tree')

// 获取职称分类树
export const getTitleTree = () => http.post('/web/title/tree')

// 保存已选职称分类
export const saveSelectedTitle = (params) => http.post('/web/title/set', params)