import http from "@/utils/http"

// 用户登录
export const login = (params) => http.post('/applet/login', params)
// 验证码登录
export const codeLogin = (params) => http.post('/applet/code/login', params)

// 获取用户信息
export const getUserInfo = () => http.post('/t-app-user/info')

// 用户注册
export const register = (params) => http.post('/t-app-user/regis', params)

// 重置密码
export const resetPassword = (params) => http.post('/t-app-user/forget', params)

// 发送验证码
export const sendVerificationCode = (params) => http.post(`/common/send?phone=${params.phone}&type=${params.type}`)

// 通用收藏接口
export const collect = (params, type) => http.post(`/t-app-user/collect?type=${type}&id=${params.id}`)

// 通用收藏接口
const collectItem = (params, type) => http.post(`/t-app-user/collect?type=${type}&id=${params.id}`)

// 收藏课程
export const collectCourse = (params) => collectItem(params, 2)

// 收藏文档
export const collectDocument = (params) => collectItem(params, 3)

// 收藏信息
export const collectInformation = (params) => collectItem(params, 1)

// 修改用户信息
export const editUserInfo = (params) => http.post(`/t-app-user/edit`, params)

// 修改手机号
export const changePhone = (params) => http.post(`/t-app-user/changephone?newPhone=${params.newPhone}&code=${params.code}`)

// 收藏咨询
export const infoList = (params) => http.post(`/t-consultation/info/list`, params)

// 收藏资料
export const infomationInfoList = (params) => http.post(`/t-information/info/list`, params)

// 取消收藏资料
export const cancelInfomationInfoList = (params) => http.post(`/t-app-user/cancel?id=${params.id}&type=${params.type}`)

// 收藏课程
export const courseInfoList = (params) => http.post(`/t-course/info/list`, params)

// 发送验证码
export const getNotice = (params) => http.get(`/system/public/getContent?key=${params.key}`)

// 购买课程
export const courseBuy = (params) => http.post(`/t-course/buy?type=${params.type}&orderId=${params.id}`)
// 购买资料
export const informationBuy = (params) => http.post(`/t-information/buy?type=${params.type}&orderId=${params.id}`)
// 生成购买资料订单
export const createInformationOrder = (params) => http.post(`/t-information/create?id=${params.id}`)

// 生成课程订单
export const createCourseOrder = (params) => http.post(`/t-course/create?id=${params.id}`)

// 取消订单
export const cancelOrder = (params) => http.post(`/t-order/cancel?orderId=${params.id}`)

// 删除订单
export const delOrder = (params) => http.post(`/t-order/delete?orderId=${params.id}`)

// 订单查询
export const getOrderList = (params) => http.post(`/t-order/web/pageList`, params)

// 生成课程订单
export const buyOrder = (params) => http.post(`/t-generated-records/buy?orderId=${params.id}&type=${params.type}`)

// 课程详情
export const courseDetail = (params) => http.post(`/t-order/course/detail?id=${params.id}&goodId=${params.goodId}`)

// 资料详情
export const informationDetail = (params) => http.post(`/t-order/order/information/detail?id=${params.id}&goodId=${params.goodId}`)

// 工作总结详情
export const generateDetail = (params) => http.post(`/t-order/generate/detail?id=${params.id}`)

export const service = (params) => http.post(`/t-info-config/getInfoConfig?id=${params.id}`)

export const usetUSerService = (params) => http.post(`/t-info-config/updateInfoConfig`,params)