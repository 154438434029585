import { tokenTableName } from "@/config"

// 获取token
export function getToken() {
    return localStorage.getItem(tokenTableName)
}

// 设置token
export function setToken(token) {
    localStorage.setItem(tokenTableName, token)
}

// 移除token
export function removeToken() {
    localStorage.removeItem(tokenTableName)
}