// 路由配置
const routes = [
    {
        path: '/',
        component: () => import('@/layout'),
        redirect: '/home',
        children: [
            { path: '/home', name: 'home', component: () => import('@/views/home'), meta: { title: '首页' } },
            { path: '/regions', name: 'regions', component: () => import('@/views/regions'), meta: { title: '切换城市' } },

            // 资讯
            { path: '/notifications', name: 'notifications', component: () => import('@/views/notifications/list'), meta: { title: '通知公告' } },
            { path: '/notifications/detail/:id', name: 'notificationDetail', component: () => import('@/views/notifications/detail'), meta: { title: '通知公告' } }
            ,
            { path: '/policies', name: 'policies', component: () => import('@/views/informations/list'), meta: { title: '政策文件' } },
            { path: '/policies/detail/:id', name: 'policyDetail', component: () => import('@/views/informations/detail'), meta: { title: '政策文件' } },
            
            { path: '/public-notices', name: 'publicNotices', component: () => import('@/views/publicNotices/list'), meta: { title: '公示信息' } },
            { path: '/public-notices/detail/:id', name: 'publicNoticeDetail', component: () => import('@/views/publicNotices/detail'), meta: { title: '公示信息' } },

            { path: '/public-education', name: 'publicEducation', component: () => import('@/views/education/list'), meta: { title: '继续教育' } }, //新加的
            { path: '/public-education/detail/:id', name: 'publicEducationDetail', component: () => import('@/views/education/detail'), meta: { title: '继续教育' } }, //新加的

            { path: '/documents', name: 'Documents', component: () => import('@/views/documents/list'), meta: { title: '资料模板' } },
            { path: '/documents/detail/:id', name: 'DocumentsDetail', component: () => import('@/views/documents/detail'), meta: { title: '资料模板' } },

            { path: '/committee', name: 'committee', component: () => import('@/views/committee/list'), meta: { title: '评审委员会' } },
            { path: '/committee/detail/:id', name: 'committeeDetail', component: () => import('@/views/committee/detail'), meta: { title: '评审委员会' } },

            { path: '/declarations', name: 'declarations', component: () => import('@/views/declarations/list'), meta: { title: '职称申报' } },
            { path: '/declarations/detail/:id', name: 'declarationsDetail', component: () => import('@/views/declarations/detail'), meta: { title: '职称申报' } },

            { path: '/courses', name: 'courses', component: () => import('@/views/courses/list'), meta: { title: '教学视频' } },
            { path: '/courses/detail/:id', name: 'coursesDetail', component: () => import('@/views/courses/detail'), meta: { title: '教学视频' } },

            { path: '/title-summary', name: 'titleSummary', component: () => import('@/views/title-summary'), meta: { title: '职称工作总结' } },

            { path: '/title-encyclopedia', name: 'titleEncyclopedia', component: () => import('@/views/title-encyclopedia/list'), meta: { title: '职称百科' } }, //新加的
            { path: '/title-encyclopedia/detail/:id', name: 'titleEncyclopediaDetail', component: () => import('@/views/title-encyclopedia/detail'), meta: { title: '职称百科' } }, //新加的

            { path: '/about-us', name: 'aboutUs', component: () => import('@/views/about-us'), meta: { title: '关于我们' } },
            { path: '/search', name: 'search', component: () => import('@/views/search'), meta: { title: '搜索结果' } },

            { path: '/user-center', name: 'userCenter', component: () => import('@/views/user-center'), meta: { title: '个人中心' } },
        ]
    },
    { path: '/login', name: 'login', component: () => import('@/views/login'), meta: { title: '登录' } },
    { path: '/register', name: 'register', component: () => import('@/views/register'), meta: { title: '注册' } },
    { path: '/reset-password', name: 'resetPassword', component: () => import('@/views/reset-password'), meta: { title: '重置密码' } },
]

export default routes