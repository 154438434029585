<template>
  <div id="app" :class="!isWeb && 'oa'">
    <router-view />
  </div>
</template>
<script>
import autofit from "autofit.js";

export default {
  name: 'App',
  data() {
    return {
      isWeb: false
    }
  },
  watch: {
    'window': {
      handler(newVlaue, oldValue) {
        if (newVlaue.innerWidth < 768) {
          autofit.init({
            // designHeight: 900,
            designWidth: 1920,
            // dh: 900,
            dw: 1920,
            el: "body",
            resize: true,
          }, false);
        }
      },
      deep: true
    },
    '$route': {
      handler(newVlaue, oldValue) {
        if (newVlaue.path == '/home' && window.innerWidth < 768) {
          this.isWeb = false
        } else {
          this.isWeb = true
        }
      },
      deep: true
    }
  },
  mounted() {
    if (window.innerWidth < 768) {
      autofit.init({
        // designHeight: 900,
        designWidth: 1920,
        // dh: 900,
        dw: 1920,
        el: "body",
        resize: true,
      }, false);
    }
  },
}
</script>
<style>
::-webkit-scrollbar {
  display: none;
}

html,
body,
.text-sm,
#app {
  height: 100%;
}

#app {
  background: #F3F4F6;
}
.oa{
  overflow: auto;
}
</style>