// 全局默认配置项

module.exports = {
  // 系统标题
  title: "职评网|全国职称申报服务_政策通知大数据平台",

  // 系统Logo
  logo: "https://tcjt-zpw.obs.cn-east-3.myhuaweicloud.com/xinquan/5cfaead6d0b648a8883d334b12079ba3.png",

  // 版权信息
  copyright:
    "Copyright ©2025 职评网（四川） 蜀ICP备18024407号-8 与 蜀ICP备18024407号-9",

  // 是否使用Mock数据
  useMock: false,

  // API接口地址
  apiUrl:
    process.env.NODE_ENV == "development"
      ? // "http://localhost:9090"
        "http://192.168.6.96:9090"
      : "https://api.zpw315.com",

  // 请求内容类型
  contentType: "application/json;charset=UTF-8",

  // 请求超时时间（毫秒）
  requestTimeout: 20000,

  // Token名称
  tokenName: "token",

  // Token存储表名
  tokenTableName: "access_token",

  // 公共路径
  publicPath: "/",

  // 路由模式
  routerMode: "hash",

  // 不需要token的路由
  publicRoutes: [
    "/login",
    "/register",
    "/home",
    "/declarations",
    "/declarations/detail/:id",
    "/committee",
    "/committee/detail/:id",
    "/public-education",
    "/public-education/detail/:id",
    "/title-encyclopedia",
    "/title-encyclopedia/detail/:id",
    "/about-us",
    "/policies",
    "/policies/detail/:id",
    "/notifications",
    "/notifications/detail/:id",
    "/public-notices",
    "/public-notices/detail/:id",
    "/title-summary",
    "/courses",
    "/documents",
  ],

  // 特殊路由
  specialRoutes: ["/reset-password"],

  // 是否在保存时进行代码检查
  lintOnSave: false,

  excludeErrorUrls: ["/common/send"],
};
