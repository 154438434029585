// src/router/index.js
import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import routes from "@/config/route";
import { publicPath, publicRoutes, routerMode, specialRoutes } from "@/config";
import { startCount, handleProgress } from "@/utils/nprogress";

// 重写 VueRouter 的 push 方法，以处理导航失败的情况
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

// 实例化路由
const router = new VueRouter({
  mode: routerMode,
  base: publicPath,
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

// 路由守卫
router.beforeEach(async (to, from, next) => {
  startCount();

  updateDocumentTitle(to.meta?.title);

  const hasToken = store.getters["user/token"];
  console.log("hasToken-", hasToken);
  if (hasToken) {
    // 禁止已登录用户返回登录页、注册页
    if (["/login", "/register"].includes(to.path)) {
      next({ path: "/", replace: true });
    } else {
      try {
        await store.dispatch("user/userInfo");
        next();
      } catch (error) {
        console.error("错误拦截:", error.message);
        await store.dispatch("user/resetAll");
        // next(navigateLogin());
        next();
      }
    }
  } else {
    // 未登录用户访问需要 token 的页面
    console.log("to-path-", to);
    // if (!publicRoutes.includes(to.path) && !specialRoutes.includes(to.path)) {
    if (
      !matchPath(to.path, publicRoutes) &&
      !matchPath(to.path, specialRoutes)
    ) {
      next(navigateLogin());
    } else {
      next();
    }
  }

  handleProgress();
});

function matchPath(path, patterns) {
  return patterns.some((pattern) => {
    const parts = pattern.split("/");
    const pathParts = path.split("/");

    if (parts.length !== pathParts.length) {
      return false;
    }

    return parts.every((part, index) => {
      if (part.startsWith(":")) {
        return true; // 忽略路径参数
      }
      return part === pathParts[index];
    });
  });
}

function updateDocumentTitle(title) {
  document.title = `${title || ""} - ${process.env.VUE_APP_TITLE}`;
}

function navigateLogin() {
  return { path: "/login", replace: true };
}

export default router;
