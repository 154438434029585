import { logo, title, copyright } from '@/config'
import * as settingService from '@/services/settings'

const state = () => ({
    logo,
    title,
    copyright,
    cityName:'',
    provinceName:'',
    regionId:'',
})

const getters = {
    logo: state => state.logo,
    title: state => state.title,
    copyright: state => state.copyright,
    cityName: state => state.cityName,
    provinceName: state => state.provinceName,
    regionId: state => state.regionId
}

const mutations = {
    setCityName(state, name) {
        localStorage.setItem('cityName', name)
        state.cityName = name
    },
    setProvinceName(state, name) {
        state.provinceName = name
    },
    setRegionId(state, id) {
        state.regionId = id
    },
}

const actions = {
    async slogan() {
        try {
            const { data: { content } } = await settingService.getSlogan()
            return content
        } catch (error) {
            console.error('Failed to fetch slogan:', error)
            throw error
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}