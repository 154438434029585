import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from 'echarts'
import http from './utils/http'
import './assets/styles/tailwind.css'
import dayjs from 'dayjs';

Vue.filter('formatNumber', (num) => {
  if (num && num >= 1000) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return num || 0
  }
})

Vue.filter('formatDate', (num) => {
  if (num) {
    return dayjs(num).format('YYYY-MM-DD HH:mm:ss')
  } else {
    return ''
  }
})

// 使用 ElementUI
Vue.use(ElementUI)

// 将 echarts 和 http 挂载到 Vue 原型上，以便在组件中使用
Vue.prototype.$echarts = echarts
Vue.prototype.$http = http

// 关闭生产模式下的提示
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
