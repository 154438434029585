import request from './request'

// 创建请求配置
const createRequestConfig = (method, url, data) => {
    const config = {
        method,
        url,
    }

    if (data) {
        if (method === 'GET' || method === 'DELETE') {
            config.params = data
        } else {
            config.data = data
        }
    }

    return config
}

const http = {
    // GET请求
    get(url, params = {}) {
        return request(createRequestConfig('GET', url, params))
    },

    // POST请求
    post(url, data = {}) {
        return request(createRequestConfig('POST', url, data))
    },

    // PUT请求
    put(url, data = {}) {
        return request(createRequestConfig('PUT', url, data))
    },

    // DELETE请求
    delete(url, params = {}) {
        return request(createRequestConfig('DELETE', url, params))
    },
}

export default http